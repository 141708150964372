<template>
  <div class="contacts">
    <div class="card full-page">
      <div class="card-body inner-scroll">
        <div v-if="hasAccess" class="contacts-table">
          <div class="feat-desc">
            <p>{{ $t('desc_feat_contacts_1') }}</p>
          </div>
          <div v-if="contacts.length === 0 && isFetched" class="row">
            <div class="d-flex m-auto flex-column">
              <NotFound />
            </div>
          </div>
          <div v-if="contacts.length" class="row">
            <div class="col-md-12">
              <div class="filter-table float-right w-25 mb-2">
                <feather-icon
                  style="color: #6bc4c5; display: inline; margin-top: 0.75em"
                  size="16"
                  icon="SearchIcon"
                  class="mr-50 float-left"
                />
                <input
                  v-model="search"
                  style="display: inline; width: 85% !important"
                  type="text"
                  class="form-control inline"
                  :placeholder="$t('Search')"
                />
              </div>
              <b-table
                striped
                hover
                responsive
                :filter="search"
                class="position-relative"
                :items="contacts"
                :fields="[
                  { fullName: { label: this.$t('fullName') } },
                  { email: { label: this.$t('Email') } },
                  { phone: { label: this.$t('Phone') } },
                  { kidName: { label: this.$t('Kid name') } },
                  { dateOfBirth: { label: this.$t('Date of birth') } },
                  { zipCode: { label: this.$t('ZIP code') } },
                  { type: { label: this.$t('Contact type') } },
                  { edit: { label: this.$t('Edit') } },
                  { sendWhatsapp: { label: this.$t('Send WhatsApp') } },
                ]"
              >
                <template #cell(email)="data">
                  <div class="w-100 text-truncate">
                    {{ data.value }}
                  </div>
                </template>
                <template #cell(phone)="data">
                  <div class="w-100 text-truncate">
                    {{ removePhonePrefix(data.value) }}
                  </div>
                </template>
                <template #cell(type)="data">
                  <div class="w-100 text-truncate">
                    {{ $t(`contact_type_${data.value}`) }}
                  </div>
                </template>
                <template #cell(edit)="data">
                  <b-link
                    :style="'min-width: 170px; display: block; text-align: center;'"
                    :disabled="!canEditContact(data.item)"
                    class="btn btn-sm btn-primary edit-button"
                    :to="{ path: `/contacts/edit/${data.value}` }"
                  >
                    {{ $t('Edit contact') }}</b-link
                  >
                </template>
                <template #cell(sendWhatsapp)="data">
                  <a
                    :style="'min-width: 170px; display: block; text-align: center;'"
                    class="btn btn-sm btn-success"
                    :class="{ disabled: !canSendWhatsapp(data.value) }"
                    :href="
                      canSendWhatsapp(data.value)
                        ? 'https://wa.me/34' + removePhonePrefix(data.value)
                        : '#'
                    "
                    target="_blank"
                  >
                    <feather-icon size="16" icon="ZapIcon" class="mr-50 white" />
                    {{ $t('Send WhatsApp') }}</a
                  >
                </template>
              </b-table>
            </div>
          </div>
        </div>
        <div v-else class="row">
          <div class="col-md-4">
            <p class="feature-text">{{ $t('blocked_feat_contacts_1') }}</p>
            <p class="feature-text">{{ $t('blocked_feat_contacts_2') }}</p>
          </div>
          <div class="col-md-8">
            <img
              src="@/assets/images/screenshots/contacts.webp"
              alt="Screenshot of the contacts page"
              class="screenshot"
            />
          </div>
        </div>
      </div>
      <div class="card-footer footer-shadow">
        <router-link v-if="hasAccess" to="/contacts/create" class="btn btn-primary">
          {{ $t('Create contact') }}
        </router-link>
        <router-link v-else to="/subscribe" class="btn btn-toddl">
          {{ $t('upgrade_plan_cta') }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { BTable, BLink } from 'bootstrap-vue'

import NotFound from '@/components/NotFound'
import userCan from '@/libs/user-can'
import ContactService from '@/services/ContactService'

export default {
  name: 'Messages',
  components: {
    NotFound,
    BLink,
    BTable,
  },
  data() {
    return {
      ContactService: new ContactService(),
      contacts: [],
      isFetched: false,
      search: '',
    }
  },
  computed: {
    hasAccess: function () {
      return userCan(this.$store.state.auth.user.plan, 'contacts')
    },
  },
  mounted() {
    if (this.hasAccess) {
      this.getContacts()
    }
  },
  methods: {
    canEditContact: function (contact) {
      return (
        userCan(this.$store.state.auth.user.plan, 'contacts_edit') &&
        ['manually_created', 'widget_user'].includes(contact.type)
      )
    },
    canSendWhatsapp: function (phone) {
      return phone && userCan(this.$store.state.auth.user.plan, 'contacts_whatsapp')
    },
    removePhonePrefix(number) {
      let num = number.toString()
      if (!num.startsWith('34') && !num.startsWith('+34')) {
        return number
      }
      number = num.replace('+', '').replace(' ', '').replace(/^34/, '')
      return number
    },
    async getContacts() {
      const data = await this.ContactService.index()
      this.contacts = data.map(contact => ({
        fullName: contact.full_name,
        zipCode: contact.zip_code,
        kidName: contact.kid_name,
        dateOfBirth: contact.kid_dob,
        email: contact.email,
        phone: contact.phone,
        edit: contact.id,
        model: contact.model,
        type: this.getContactType(contact),
        sendWhatsapp: contact.phone,
      }))
      this.isFetched = true
    },
    getContactType: function (contact) {
      if (contact.model === 'App\\Models\\Contact') {
        return contact.created_manually ? 'manually_created' : 'widget_user'
      } else {
        return contact.kid_name ? 'marketplace_user' : 'marketplace_guest'
      }
    },
  },
}
</script>

<style scoped>
.edit-button {
  min-height: 32px;
}

.screenshot {
  width: 100%;
  height: 100%;
  aspect-ratio: 16 / 10;
}

.feature-text {
  font-size: 1.2rem;
  color: #333;
}

table td {
  min-width: 150px;
}

input[type='text'] {
  width: 100% !important;
}
</style>
