var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contacts"},[_c('div',{staticClass:"card full-page"},[_c('div',{staticClass:"card-body inner-scroll"},[(_vm.hasAccess)?_c('div',{staticClass:"contacts-table"},[_c('div',{staticClass:"feat-desc"},[_c('p',[_vm._v(_vm._s(_vm.$t('desc_feat_contacts_1')))])]),(_vm.contacts.length === 0 && _vm.isFetched)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"d-flex m-auto flex-column"},[_c('NotFound')],1)]):_vm._e(),(_vm.contacts.length)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"filter-table float-right w-25 mb-2"},[_c('feather-icon',{staticClass:"mr-50 float-left",staticStyle:{"color":"#6bc4c5","display":"inline","margin-top":"0.75em"},attrs:{"size":"16","icon":"SearchIcon"}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"form-control inline",staticStyle:{"display":"inline","width":"85% !important"},attrs:{"type":"text","placeholder":_vm.$t('Search')},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}})],1),_c('b-table',{staticClass:"position-relative",attrs:{"striped":"","hover":"","responsive":"","filter":_vm.search,"items":_vm.contacts,"fields":[
                { fullName: { label: this.$t('fullName') } },
                { email: { label: this.$t('Email') } },
                { phone: { label: this.$t('Phone') } },
                { kidName: { label: this.$t('Kid name') } },
                { dateOfBirth: { label: this.$t('Date of birth') } },
                { zipCode: { label: this.$t('ZIP code') } },
                { type: { label: this.$t('Contact type') } },
                { edit: { label: this.$t('Edit') } },
                { sendWhatsapp: { label: this.$t('Send WhatsApp') } } ]},scopedSlots:_vm._u([{key:"cell(email)",fn:function(data){return [_c('div',{staticClass:"w-100 text-truncate"},[_vm._v(" "+_vm._s(data.value)+" ")])]}},{key:"cell(phone)",fn:function(data){return [_c('div',{staticClass:"w-100 text-truncate"},[_vm._v(" "+_vm._s(_vm.removePhonePrefix(data.value))+" ")])]}},{key:"cell(type)",fn:function(data){return [_c('div',{staticClass:"w-100 text-truncate"},[_vm._v(" "+_vm._s(_vm.$t(("contact_type_" + (data.value))))+" ")])]}},{key:"cell(edit)",fn:function(data){return [_c('b-link',{staticClass:"btn btn-sm btn-primary edit-button",style:('min-width: 170px; display: block; text-align: center;'),attrs:{"disabled":!_vm.canEditContact(data.item),"to":{ path: ("/contacts/edit/" + (data.value)) }}},[_vm._v(" "+_vm._s(_vm.$t('Edit contact')))])]}},{key:"cell(sendWhatsapp)",fn:function(data){return [_c('a',{staticClass:"btn btn-sm btn-success",class:{ disabled: !_vm.canSendWhatsapp(data.value) },style:('min-width: 170px; display: block; text-align: center;'),attrs:{"href":_vm.canSendWhatsapp(data.value)
                      ? 'https://wa.me/34' + _vm.removePhonePrefix(data.value)
                      : '#',"target":"_blank"}},[_c('feather-icon',{staticClass:"mr-50 white",attrs:{"size":"16","icon":"ZapIcon"}}),_vm._v(" "+_vm._s(_vm.$t('Send WhatsApp')))],1)]}}],null,false,2716874315)})],1)]):_vm._e()]):_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('p',{staticClass:"feature-text"},[_vm._v(_vm._s(_vm.$t('blocked_feat_contacts_1')))]),_c('p',{staticClass:"feature-text"},[_vm._v(_vm._s(_vm.$t('blocked_feat_contacts_2')))])]),_vm._m(0)])]),_c('div',{staticClass:"card-footer footer-shadow"},[(_vm.hasAccess)?_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":"/contacts/create"}},[_vm._v(" "+_vm._s(_vm.$t('Create contact'))+" ")]):_c('router-link',{staticClass:"btn btn-toddl",attrs:{"to":"/subscribe"}},[_vm._v(" "+_vm._s(_vm.$t('upgrade_plan_cta'))+" ")])],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-8"},[_c('img',{staticClass:"screenshot",attrs:{"src":require("@/assets/images/screenshots/contacts.webp"),"alt":"Screenshot of the contacts page"}})])}]

export { render, staticRenderFns }